<template>
  <h2>最新完成报告</h2>
  <div class="card">
    <DataTable :rowHover="true" :value="taskItems" dataKey="id">
      <!-- <Column selectionMode="multiple" headerStyle="width: 2em"></Column> -->
      <Column field="name" header="报告编号">
        <template #body="slotProps">
          {{ slotProps.data.name.substring(3) }}
        </template>
      </Column>
      <Column field="labchargename" header="实验员">
        <template #body="slotProps">
          {{ slotProps.data.labchargename }}
        </template>
      </Column>
      <Column field="status" header="报告状态">
        <template #body="slotProps">
          <ReportStatus :num="slotProps.data.status" />
        </template>
      </Column>

      <Column field="labcharge_at" header="提交日期">
        <template #body="slotProps">
          <DateLabel :mydate="formatDate(slotProps.data.labcharge_at)" />
        </template>
      </Column>
    </DataTable>
  </div>
  <Paginator
    :pageLinkSize="7"
    :rows="20"
    :totalRecords="1000"
    @page="onPage($event)"
  >
  </Paginator>
  <p>当前第{{ curPage }}页</p>
</template>

<script>
import { baseUrl } from "@/config.js";
import auth from "@/store/modules/auth";
import { onMounted, ref, watchEffect, onUnmounted } from "vue";
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ReportStatus from "@/components/ReportStatus";
import DateLabel from "@/components/DateLabel";

export default {
  name: "TopReport",
  props: {},
  components: {
    Paginator,
    DataTable,
    Column,
    ReportStatus,
    DateLabel,
  },
  data() {
    return {};
  },
  setup() {
    const curPage = ref(1);

    const taskItems = ref([]);

    const fetchTask = async (page) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/report/${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        taskItems.value = await response.json();
      } catch (error) {
        console.error(error);
      }
    };
    watchEffect(() => {
      fetchTask(curPage.value);
    });
    onMounted(() => {
      fetchTask(curPage.value);
    });
    onUnmounted(() => {});

    return { taskItems, curPage };
  },
  computed: {},
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    onPage(event) {
      this.curPage = event.page + 1;
    },
  },
};
</script>

<style scoped>
.p-paginator-pages {
  background-color: brown;
}
.red {
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(226, 44, 44);
  color: white;
}
.yello {
  background-color: yellow;
  color: white;
}

.normal {
  background-color: white;
  color: black;
}
.grap {
  margin-left: 20px;
}
h2 {
  text-align: center;
}
</style>
